import React from "react"
import { Helmet } from "react-helmet"

import FullScreenMessage from "../components/molecules/FullScreenMessage"
import { DarkButton } from "../components/atoms/Button"

const NotFoundResponse = () => (
  <>
    <Helmet>
      <title>Thank You!</title>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <FullScreenMessage heading="Thanks for contacting us!" msg="We'll be in touch shortly.">
      <DarkButton link="/">Return home</DarkButton>
    </FullScreenMessage>
  </>
)

export default NotFoundResponse
